<template>
    <div class="edge_container">
        <!-- 标题部分 -->
        <div class="edge_title">
            <h3 class="animated bounceInLeft">「 {{ titleAreaData.text }} 」</h3>
            <p class="common_title_p animated bounceInRight">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_line animated bounceInLeft"></div>
            <small class="animated bounceInRight" @click="immediately"
            >立即咨询</small
            >
        </div>

        <div class="container_architecture"
             v-for="(item, index) in inventoryList"
             :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2"
                    style="margin-top: 90px;font-weight:600;font-size:30px">
                    {{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon">
            </div>
        </div>

        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart
                :emailPhoneInfo="emailPhone"
                :codeInfo="codeQR"
            ></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>
<script>
import commonProduct
    from '../../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin
    from '../../../../components/floatingWindow/floatingWindow.vue'
import demand from '../../../../api/data.js'
import $ from 'jquery'

export default {
    inject: ['reload'],

    components: {commonProduct, commonBottomPart, commonFloatWin},
    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            titleAreaData: {
                text: 'OA办公',
                describe:
                    '无纸化办公，提升管理能力，融合工业互联网'
            },

            activeName: 'first',

            productList: [],
            emailPhone: {},
            codeQR: [],
            labelStyle1: {
                background: '#8CC5FF',
                width: '160px',
                color: '#303133'
            },
            contentStyle1: {
                background: '#C6E2FF',
                color: '#606266'
            },
            inventoryList: [
                {
                    id: '1',
                    title: 'OA个性化管理',
                    superiorityIcon: require('../../../../assets/img/product/application/oa/oa.png')
                },
            ]
        }
    },
    methods: {
        // 滚动监听 动画监听
        handleScroll(e) {
            let scrollHeight = e.target.scrollTop // 滚动条的滚动行程
            if (scrollHeight > 500) {
                $('.edge_agreement').removeClass('displayNone')
                $('.edge_agreement > h2').addClass('animated bounceInUp')
                $('.edge_agreement > div').addClass('animated bounceInUp')
            }
            if (scrollHeight > 1500) {
                $('.edge_superiority').removeClass('displayNone')
                $('.edge_superiority h2').addClass('animated bounceInUp')
                $('.edge_superiority > ul').addClass('animated bounceInLeft')
            }
        },
        // 初始化基本信息
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },
        // 立即购买
        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        },
        // 图片查看
        viewImg(src) {
            this.$common.showViewImgDialog(src)
        }
    }
}
</script>
<style lang="less" scoped>
.container_architecture {
    width: 1915PX;
    margin: 50px auto;

    img {
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.edge_container {
    color: #fff;
    position: relative;
    background: #fff;
}

.line_top {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    margin: 30px 0 0 40%;
}

.line_bottom {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    position: relative;
    top: 102px;
    left: 55%;
}

.main_catchword_h2 {
    font-size: 30px;
    font-weight: 100;
    text-align: center;
    color: #000;
}

.edge_title {
    height: 660px;
    background: url('../../../../assets/img/titlebackground/titleB58.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;

    h3 {
        margin: 0;
        font-size: 60px;
        line-height: 200%;
        font-weight: bold;
        padding-top: 200px;
    }

    .common_title_p {
        width: 50%;
        line-height: 200%;
        padding: 0 25%;
        font-size: 22px;
        //letter-spacing: 2px;
    }

    .title_line {
        width: 5%;
        height: 1px;
        background: #2db5a3;
        margin: 6% 0 0 64%;
    }

    small {
        font-size: 18px;
        position: absolute;
        top: 72.5%;
        left: 70%;
        cursor: pointer;
        padding: 10px 20px;
        background: #2db5a3;
        border-radius: 4px;
    }
}

// 产品介绍
.edge_introduce {
    height: 600px;
    padding: 20px 20%;
    text-align: center;

    p {
        color: #333;
        font-size: 16px;
        text-indent: 2rem;
        line-height: 200%;
        //letter-spacing: 2px;
        text-align: left;
    }
}

// // 平台功能 20220923
.platformFunction {
    height: 650px;
    background: url('../../../../assets/img/home/technicalSupport.jpg');
    background-size: cover;
    background-position: 100%;
    // background: #00000052;
    .technicalSupport_shade {
        color: #fff;
        width: calc(100% - 480px);
        height: calc(100% - 40px);
        margin: 50px 0;
        padding: 20px 240px;
        text-align: center;
        background: #00000052;

        .technicalSupport_h2 {
            //letter-spacing: 2px;
            margin: 0;
            text-align: center;
        }

        .technicalSupport_line {
            height: 1px;
            background: chartreuse;
            width: 60px;
            position: relative;
            top: 20px;
            left: 49%;
        }
    }

    .el-tabs {
        margin-top: 40px !important;

        .tabBody {
            color: #303133;
            height: 400px;
            margin-top: 20px;
            display: flex;

            .tabBody-img {
                width: 40%;

                img {
                    position: relative;
                    border-radius: 10px 0 0 10px;
                    width: 100%;
                    height: 100%;
                }
            }

            .tabBody-content {
                border: 1px solid gray;
                width: 60%;
                height: 100%;
                background: #fff;
                border-radius: 0 10px 10px 0;
                border: 0.5px solid #dfe0e4;
                box-shadow: inset 0.5px 0 0.5px #bebfc2;

                h3 {
                    width: 60%;
                    text-align: center;
                    margin: 30px 20% 20px 20%;
                }

                p {
                    line-height: 30px;
                    text-indent: 2rem;
                    text-align: left;
                    width: 60%;
                    margin-left: 20%;
                }

                .describeArr {
                    line-height: 20px;
                }
            }
        }

        .el-carousel {
            height: 450px;

            .el-carousel__item {
                height: 425px;
                background: #000612;
                // background-color:rgba(255,255,255,0.6);
                // opacity: 0;
                img {
                    margin: 0;
                    height: 400px;
                    width: 800px;
                    position: relative;
                    opacity: 1;
                }
            }
        }
    }

    /deep/ .el-tabs__item {
        color: #fff;
    }

    /deep/ .el-tabs__item.is-active {
        color: #409eff;
    }
}

//协议
.edge_agreement {
    height: 700px;
    background-size: cover;
    padding: 30px 18%;
    position: relative;
    background: #ebeef5;

    .agreement {
        width: 100%;
        height: 95%;
        display: inline-block;
        position: relative;
        overflow: hidden;
        margin-top: 60px;
    }

    .agreementItem {
        width: 100%;
        height: 100%;
        list-style-type: none;
        overflow: hidden;
        margin: 0 auto;
        position: relative;

        li {
            width: 21.5%;
            color: black;
            float: left;
            padding: 20px 12px;
            margin: 10px 0px 0px 14px;
            border-radius: 5px;
            position: relative;
            background: #fff;
            border: 1px solid #ebeef5;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }

        //定位到li最后一个孩子节点
        // .agreementInfo:last-child{
        //   text-align: center;
        // }
        h3 {
            font-weight: normal;
            font-size: 18px;
            color: #606266;
            margin: 0;
            background: #ebeef5;
            width: 100%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 4px;
        }

        .agreementImg {
            width: 200px;
            height: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 28px;
        }

        img {
            width: 85%;
            cursor: pointer;
            transition: all 0.6s;
        }

        img:hover {
            position: relative;
            transform: scale(1.4);
            z-index: 1;
        }

        .agreementInfo {
            width: 90%;
            margin-top: 10px;
        }

        p {
            width: 100%;
            font-size: 14px;
            width: 90%;
            padding-left: 45px;
            color: #666666;
            line-height: 14px;
        }
    }
}

// 解决方案
.edge_solution {
    height: 1300px;
    padding: 30px 20%;
    position: relative;

    ul {
        margin-top: 80px;
        border-radius: 5px;
        border: 1px solid #ebeef5;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebeef5;

        .viewLeft,
        .viewRight {
            padding: 0px 10%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 120px;
                border-radius: 5px;
                display: block;
                // 两端对齐
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15px;
                text-indent: 2rem;
                line-height: 20px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

//配置性能
.edge_configuration, .edge_performance {
    height: 380px;
    padding: 0 15%;

    ul {
        width: 100%;
        height: 300px;
        margin-left: 6%;
        // margin: 10px auto;
        li {
            float: left;
            width: 23%;
            margin: 3% 0 0 3%;
            padding: 10px 20px;
            text-align: center;
            //letter-spacing: 2px;
            img {
                height: 90px;
                display: block;
                margin: 0 0 20px 37%;
            }

            p {
                //letter-spacing: 1px;
                text-indent: 2rem;
                line-height: 20px;
                color: #5e6d82;
            }

            strong {
                height: 60px;
                color: #000;
            }
        }
    }
}

// .edge_superiority,
// .edge_function {
//   height: 640px;
//   padding: 30px 20%;
// }
// 产品功能
// .edge_function {
//   padding: 30px 20%;
//   height: 660px;
//   background: #ebeef5;
// }
.edge_function {
    height: 700px;
    padding: 0 15%;

    ul {
        width: 100%;
        height: 680px;
        padding-top: 50px;
        margin-left: 4%;

        li {
            float: left;
            width: 18%;
            height: 290px;
            // margin: 3% 0;
            // padding: 10px 30px;
            text-align: center;
            //letter-spacing: 2px;
            border-radius: 4px;
            border: 1px solid #ebeef5;
            box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.1);

            img {
                height: 70px;
                display: block;
                margin: 40px 0 20px 32%;
            }

            strong {
                height: 100px;
                font-size: 14.5px;

                color: #000;
            }
        }
    }
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

.introduce_leftImg {
    cursor: url('../../../../assets/img/other/zoom.png'), auto;
}

.el-descriptions {
    margin-top: 60px !important;
}

/* 小屏幕（平板，大于等于 768px） */
// @media only screen and (max-width:767px) and (min-width: 375px) {
@media (max-width: 768px) and (min-width: 391px) {
    .edge_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem;
        font-weight: bold;
    }

    .edge_title {
        height: 78rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 11%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 17% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            // display: none;
        }
    }

    //  产品简介
    .edge_introduce {
        height: 146.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 3rem;
            text-indent: 6rem;
            line-height: 200%;
        }

        img {
            width: 95.5rem;
            padding-right: 4rem;
        }

    }

    // 平台功能
    .platformFunction {
        height: 100rem;

        .technicalSupport_shade {
            width: calc(100% - 3rem);
            margin: 3.125rem 0;
            padding: 1.25rem 2rem;

            h2 {
                font-size: 4rem;
            }

            .item_lists {
                font-size: 2.1rem !important;
                padding: 0rem 0rem;
                line-height: 5.5rem !important;

                /deep/ .el-tabs__item {
                    font-size: 2.3rem;
                }

            }

            .item_name {
                // font-size: 2.5rem !important;
                // padding: 0px 0.8rem;
                // line-height: 10.5rem!important;
            }

            .tabBody {
                height: 50rem;
                // .tabBody-img{
                // //  width: 500px;
                // img{
                //   width: 100%;
                //   height: 80%;
                // }
                // }
                .tabBody-content {
                    h3 {
                        width: 78%;
                        margin: 1.875rem 20% 1.25rem 11%;
                        font-size: 3.875rem;
                    }

                    p, .describeArr {
                        margin-top: 0.2rem;
                        line-height: 5rem;
                        text-indent: 4rem;
                        text-align: left;
                        width: 96%;
                        margin-left: 1%;
                        font-size: 2.875rem;
                        text-overflow: ellipsis;
                        // 溢出隐藏
                        overflow: hidden;
                    }
                }
            }
        }

        // 轮播图
        .el-carousel {
            height: 58.125rem !important;

            /deep/ .el-carousel__container {
                position: relative;
                height: 57.75rem;
            }

            /deep/ .el-carousel__button {
                width: 2rem;
                height: 0.325rem;
            }

            .el-carousel__item {
                height: 54.5625rem !important;

                h3 {
                    font-size: 3.5rem;
                }

                // opacity: 0;
                img {
                    height: 49rem !important;
                    width: 86rem !important;
                }
            }
        }
    }

    // 产品类型
    .edge_agreement {
        height: 178.75rem;
        padding: 1.875rem 2%;

        .agreementItem {
            // height: 80rem;
            li {
                width: 43.5%;
                height: 72rem;
                padding: 1.25rem 2.75rem;
                margin: 1.625rem 0 0 1rem;
                border-radius: 0.3125rem;

                h3 {
                    font-size: 5.125rem;
                    color: #0e0f0f;
                    height: 6.25rem;
                    line-height: 6.25rem;
                }

                .agreementImg {
                    width: 22.5rem;
                    height: 24rem;
                    margin: 2.1875rem 13.75rem;

                    img {
                        width: 100%;
                    }
                }

                .agreementInfo {
                    p {
                        font-size: 2.875rem;
                        width: 98%;
                        padding-left: 3.8125rem;
                        line-height: 4.875rem;
                    }
                }
            }
        }
    }

    // 配置性能
    // .edge_superiority{
    //     height: 128rem;
    //     padding: 1.875rem 6%;
    //     .el-descriptions {
    //       font-size: 3rem;
    //       font-weight: 700;
    //     }
    //     /deep/.el-descriptions__title {
    //       font-size: 3rem;
    //       font-weight: 700;
    //   }
    // }
    // 产品配置
    // 产品性能
    .edge_configuration, .edge_performance {
        height: 66.75rem;
        padding: 0 4%;

        ul {
            margin-left: 0%;

            li {
                width: 31%;
                margin: 3% 0 0 1%;
                padding: 3.625rem 0.25rem;

                img {
                    height: 8.625rem;
                }

                p {
                    font-size: 2.8rem;
                    line-height: 4.25rem;
                }

                strong {
                    font-size: 3rem;
                    // font-weight: bold;
                }
            }
        }
    }

    // 产品功能
    .edge_function {
        height: 220.75rem;
        padding: 0 3%;

        ul {
            // height: 228.75rem;
            margin-left: 2.5%;
            padding-top: 5.125rem;

            li {
                width: 47%;
                height: 40rem;
                // border:none;
                img {
                    height: 8.375rem;
                    margin: 2rem 0 1.25rem 39%;
                }

                strong {
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 产品功能
    // .edge_function{
    //     height: 198rem;
    //     padding: 1.875rem 6%;
    //     .el-descriptions {
    //       font-size: 3rem;
    //       font-weight: 700;
    //     }
    //     .margin-top{
    //       padding-top: 5rem;
    //     }
    // }
    // 解决问题
    .edge_solution {
        height: 258rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 8rem;

            li {
                width: 100%;
                padding: 0.875rem 0%;
                height: 38rem;

                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;
    }
}

@media only screen and (max-width: 490px) {
    .edge_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem !important;
        font-weight: bold;
    }

    .edge_title {
        height: 80rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 11%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 17% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            // display: none;
            border-radius: 1.25rem;
        }
    }

    //  产品简介
    .edge_introduce {
        height: 146.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 3rem;
            text-indent: 6rem;
            line-height: 200%;
        }

        img {
            width: 95.5rem;
            padding-right: 4rem;
        }

    }

    // 平台功能
    .platformFunction {
        height: 100rem;

        .technicalSupport_shade {
            width: calc(100% - 3rem);
            margin: 3.125rem 0;
            padding: 1.25rem 2rem;

            h2 {
                font-size: 4rem;
            }

            .item_lists {
                font-size: 2.1rem !important;
                padding: 0rem 0rem;
                line-height: 5.5rem !important;

                /deep/ .el-tabs__item {
                    font-size: 2.3rem;
                }

            }

            .item_name {
                // font-size: 2.5rem !important;
                // padding: 0px 0.8rem;
                // line-height: 10.5rem!important;
            }

            .tabBody {
                height: 50rem;
                // .tabBody-img{
                // //  width: 500px;
                // img{
                //   width: 100%;
                //   height: 80%;
                // }
                // }
                .tabBody-content {
                    h3 {
                        width: 78%;
                        margin: 1.875rem 20% 1.25rem 11%;
                        font-size: 3.875rem;
                    }

                    p, .describeArr {
                        margin-top: 0.2rem;
                        line-height: 5rem;
                        text-indent: 4rem;
                        text-align: left;
                        width: 96%;
                        margin-left: 1%;
                        font-size: 2.875rem;
                        text-overflow: ellipsis;
                        // 溢出隐藏
                        overflow: hidden;
                    }
                }
            }
        }

        // 轮播图
        .el-carousel {
            height: 58.125rem !important;

            /deep/ .el-carousel__container {
                position: relative;
                height: 57.75rem;
            }

            /deep/ .el-carousel__button {
                width: 2rem;
                height: 0.325rem;
            }

            .el-carousel__item {
                height: 54.5625rem !important;

                h3 {
                    font-size: 3.5rem;
                }

                // opacity: 0;
                img {
                    height: 49rem !important;
                    width: 86rem !important;
                }
            }
        }
    }

    // 产品类型
    .edge_agreement {
        height: 178.75rem;
        padding: 1.875rem 2%;

        .agreementItem {
            // height: 80rem;
            li {
                width: 43.5%;
                height: 72rem;
                padding: 1.25rem 2.75rem;
                margin: 1.625rem 0 0 0.725rem;
                border-radius: 0.3125rem;

                h3 {
                    font-size: 5.125rem;
                    color: #0e0f0f;
                    height: 6.25rem;
                    line-height: 6.25rem;
                }

                .agreementImg {
                    width: 22.5rem;
                    height: 24rem;
                    margin: 2.1875rem 13.75rem;

                    img {
                        width: 100%;
                    }
                }

                .agreementInfo {
                    p {
                        font-size: 2.875rem;
                        width: 98%;
                        padding-left: 3.8125rem;
                        line-height: 4.875rem;
                    }
                }
            }
        }
    }

    // 配置性能
    // .edge_superiority{
    //     height: 128rem;
    //     padding: 1.875rem 6%;
    //     .el-descriptions {
    //       font-size: 3rem;
    //       font-weight: 700;
    //     }
    //     /deep/.el-descriptions__title {
    //       font-size: 3rem;
    //       font-weight: 700;
    //   }
    // }
    // 产品配置
    // 产品性能
    .edge_configuration, .edge_performance {
        height: 66.75rem;
        padding: 0 4%;

        ul {
            margin-left: 0%;

            li {
                width: 31%;
                margin: 3% 0 0 1%;
                padding: 3.625rem 0.25rem;

                img {
                    height: 8.625rem;
                }

                p {
                    font-size: 2.8rem;
                    line-height: 4.25rem;
                }

                strong {
                    font-size: 3rem;
                    // font-weight: bold;
                }
            }
        }
    }

    // 产品功能
    .edge_function {
        height: 242.75rem;
        padding: 0 3%;

        ul {
            // height: 228.75rem;
            margin-left: 2.5%;
            padding-top: 5.125rem;

            li {
                width: 47%;
                height: 44rem;
                // border:none;
                img {
                    height: 8.375rem;
                    margin: 2rem 0 1.25rem 39%;
                }

                strong {
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 产品功能
    // .edge_function{
    //     height: 198rem;
    //     padding: 1.875rem 6%;
    //     .el-descriptions {
    //       font-size: 3rem;
    //       font-weight: 700;
    //     }
    //     .margin-top{
    //       padding-top: 5rem;
    //     }
    // }
    // 解决问题
    .edge_solution {
        height: 258rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 8rem;

            li {
                width: 100%;
                padding: 0.875rem 0%;
                height: 38rem;

                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;
    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 17rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
}

</style>
