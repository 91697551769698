<template>
    <div class="comm_container">
        <!-- 标题部分 -->
        <div class="common_banner">
            <h3 class="animated bounceInLeft">「 {{ titleAreaData.text }} 」</h3>
            <p class="common_title_p animated bounceInRight">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_line animated bounceInLeft"></div>
            <small class="animated bounceInRight" @click="immediately">立即咨询</small>
        </div>

        <!-- 低代码 -->
        <div class="container_architecture" v-for="(item) in lowCodeList" :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2">{{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon" />
            </div>
        </div>

        <!-- 产品优势 -->
        <div class="common_superiority" v-if="superiorityList.length>0">
            <h2 animated bounceInLeft class="main_catchword_h2">产品优势</h2>
            <ul>
                <li v-for="(item, index) in superiorityList" :key="item.id">
                    <div class="viewLeft">
                        <img :src="item.superiorityIcon" v-if="(index + 1) % 2 == 0" alt="" />
                        <strong v-if="(index + 1) % 2 != 0">{{ item.title }}</strong>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text }}</p>
                    </div>

                    <div class="viewRight">
                        <img :src="item.superiorityIcon" v-if="(index + 1) % 2 != 0" alt="" />
                        <strong v-if="(index + 1) % 2 == 0">{{ item.title }}</strong>
                        <p v-if="(index + 1) % 2 == 0">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>

        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart :emailPhoneInfo="emailPhone" :codeInfo="codeQR" />
        </div>

        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonProduct from '../../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../../components/floatingWindow/floatingWindow.vue'


export default {
    inject: ['reload'],

    components: {commonProduct, commonBottomPart, commonFloatWin},

    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            lowCodeList: [
                {
                    id: '1',
                    title: '低代码平台',
                    superiorityIcon: require('../../../../assets/img/product/platform/lowcode/lowcode.png')
                },
                {
                    id: '2',
                    title: '开发流程',
                    superiorityIcon: require('../../../../assets/img/product/platform/lowcode/development.png')
                },
                {
                    id: '3',
                    title: '智能事件中心',
                    superiorityIcon: require('../../../../assets/img/product/platform/lowcode/Intelligence.png')
                }
            ],
            titleAreaData: {
                text: '低代码平台',
                describe: ' 缩短开发周期，快速响应需求变化'
            },

            superiorityList: [],
            productList: [],
            emailPhone: {},
            codeQR: []
        }
    },
    methods: {
        // 初始化基本信息
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },

        // 立即购买
        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        }
    }
}
</script>

<style lang="less" scoped>
.comm_container {
    color: #fff;
    background: #ffffff;
}

.container_architecture {
    //   height: 840PX;
    width: 1915PX;
    margin: 50px auto;

    .main_catchword_h2 {
        margin-top: 85PX;
    }

    img {
        // height:730PX;
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.common_banner {
    height: 660px;
    background: url('../../../../assets/img/titlebackground/titleB7.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    // opacity: 0.8;
    h3 {
        margin: 0;
        font-size: 60px;
        line-height: 200%;
        font-weight: bold;
        padding-top: 200px;
    }

    .common_title_p {
        width: 50%;
        line-height: 200%;
        padding: 0 25%;
        font-size: 22px;
        //letter-spacing: 2px;
    }

    .title_line {
        width: 5%;
        height: 1px;
        background: #2db5a3;
        margin: 5% 0 0 61%;
    }

    small {
        font-size: 18px;
        position: absolute;
        top: 70%;
        left: 68%;
        cursor: pointer;
        padding: 10px 20px;
        background: #2db5a3;
        border-radius: 4px;
    }

    small:hover {
        font-weight: bold;
    }

}

.line_top {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    margin: 30px 0 0 40%;
}

.line_bottom {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    position: relative;
    top: 102px;
    left: 55%;
}

.main_catchword_h2 {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 70px;
}

// 产品简介
.common_introduce {
    height: 380px;
    padding: 30px;
    position: relative;

    .leftInfo {
        width: 700px;
        height: 350px;
        float: left;
        margin-top: 80px;
        margin-left: 20px;

        p {
            float: left;
            width: 550px;
            height: 140px;
            color: black;
            font-size: 16px;
            line-height: 40px;
            text-indent: 2rem;
            //letter-spacing: 2px;
            position: relative;
            // margin-top: 10px;
            margin-left: 300px;
        }
    }

    .rightImg {
        position: relative;
        width: 600px;
        height: 380px;
        float: left;
        left: 400px;

        img {
            width: 350px;
            height: 300px;

        }

        p {
            padding-top: -10px;
            color: black;
            height: 20px;
            font-size: 22px;
            margin-left: 120px;
            text-indent: 2rem;
            //letter-spacing: 2px;
            text-shadow: 0px 1px 1px gray;
        }
    }
}

//产品优势
.common_superiority {
    height: 3900px;
    width: 1282PX;
    margin: 0 auto;
    position: relative;

    ul {
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 10px;

        .viewLeft,
        .viewRight {
            padding: 0px 10%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 320px;
                height: 300px;
                border-radius: 5px;
                display: block;
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15px;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

// 相关展示
.common_display {
    height: calc(1150px - 80px);
    padding: 40px 0px 40px 9%;

    h2 {
        font-size: 30px;
        color: #000;
        padding: 10px 41%;
        margin: 0 0 40px 0;
    }

    img {
        border: #2db5a3 1px solid;
        width: 30%;
        height: 300px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    img:hover {
        border: 1px solid #e6e6e6;
        position: relative;
        transform: scale(1.5);
        z-index: 100;
    }
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

/* 小屏幕（平板，大于等于 768px） */
// @media only screen and (max-width:767px) and (min-width: 375px) {
@media only screen and (max-width: 768px) {
    .comm_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem !important;
        font-weight: bold;
    }


    // 标题
    .common_banner {
        height: 77rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 9%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 16.5% 0 0 60%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            border-radius: 1.25rem
            // display: none;
        }
    }

    //  通信服务简介
    .common_introduce {
        height: 70.25rem;
        padding: 1.25rem 6%;

        .leftInfo {
            p {
                float: left;
                width: 64.375rem;
                height: 8.75rem;
                font-size: 4rem;
                line-height: 6.5rem;
                text-indent: 8rem;
                margin-left: 0rem;
            }
        }

        .rightImg {
            padding: 8rem 2%;

            img {
                width: 34.875rem;
                height: 34.75rem;
            }
        }
    }

    // 产品优势
    .common_superiority {
        height: 468rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 9rem;

            li {
                width: 100%;
                padding: 3.875rem 0%;
                // height: 60rem;
                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 相关展示
    .common_display {
        height: 236rem;
        padding: 3.875rem 5%;

        h2 {
            font-size: 5.875rem;
            color: rgb(59, 58, 58);
            padding: 2.625rem 39%;
        }

        img {
            margin-left: 0.5rem;
            width: 48%;
            height: 39.75rem;
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;

    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 18rem;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
}


</style>
