<template>
    <div class="iot_container">
        <!-- 标题部分 -->
        <div class="iot_title">
            <h3 class="animated bounceInLeft">「 {{ titleAreaData.text }} 」</h3>
            <p class="common_title_p animated bounceInRight">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_line animated bounceInLeft"></div>
            <small class="animated bounceInRight" @click="immediately">立即咨询</small>
        </div>

        <!-- 产品介绍 -->
        <div class="container_architecture" v-for="(item) in bigDataList"
             :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2" style="margin-top: 90px;font-weight:600;font-size:30px">
                    {{ item.title }}
                </h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon" />
            </div>
        </div>

        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart :emailPhoneInfo="emailPhone" :codeInfo="codeQR"></common-BottomPart>
        </div>

        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonProduct from '../../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../../components/floatingWindow/floatingWindow.vue'

export default {
    inject: ['reload'],

    components: {commonProduct, commonBottomPart, commonFloatWin},

    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            titleAreaData: {
                text: '大数据平台',
                describe: '数据驱动，智能决策'
            },

            productList: [],
            emailPhone: {},
            codeQR: [],

            bigDataList: [
                {
                    id: '1',
                    title: '大数据平台架构',
                    superiorityIcon: require('../../../../assets/img/product/platform/bigdata/bigData1.png')
                },
                {
                    id: '2',
                    title: '大数据体系结构，PDCA',
                    superiorityIcon: require('../../../../assets/img/product/platform/bigdata/bigData2.png')
                },
            ]
        }
    },

    methods: {
        // 初始化基本信息
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },

        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        },
    }
}
</script>

<style lang="less" scoped>
.container_architecture {
    width: 1915PX;
    margin: 50px auto;

    img {
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.iot_container {
    color: #fff;
    position: relative;
    background: #fff;
}

.line_top {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    margin: 30px 0 0 40%;
}

.line_bottom {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    position: relative;
    top: 102px;
    left: 55%;
}

.main_catchword_h2 {
    font-size: 30px;
    font-weight: 100;
    text-align: center;
    color: #000;
}

.iot_title {
    height: 660px;
    background: url('../../../../assets/img/titlebackground/titleB51.png') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;

    h3 {
        margin: 0;
        font-size: 60px;
        line-height: 200%;
        font-weight: bold;
        padding-top: 200px;
    }

    .common_title_p {
        width: 50%;
        line-height: 200%;
        padding: 0 25%;
        font-size: 22px;
        //letter-spacing: 2px;
    }

    .title_line {
        width: 5%;
        height: 1px;
        background: #2db5a3;
        margin: 6% 0 0 64%;
    }

    small {
        font-size: 18px;
        position: absolute;
        top: 72.5%;
        left: 70%;
        cursor: pointer;
        padding: 10px 20px;
        background: #2db5a3;
        border-radius: 4px;
    }
}

// 产品介绍
.iot_introduce {
    height: 500px;
    padding: 20px 20%;
    text-align: center;

    p {
        color: #333;
        font-size: 16px;
        text-indent: 2rem;
        line-height: 200%;
        //letter-spacing: 2px;
        text-align: left;
    }

    img {
        width: 500px;
        margin-bottom: 10px;
    }
}

//协议
.iot_agreement {
    height: 760px;
    padding: 30px 20%;
    text-align: center;
    background: #ebeef5;

    img {
        width: 750px;
        margin: 40px 0;
    }
}

.iot_platformConfiguration,
.iot_platformPerformance {
    height: 380px;
    padding: 0 15%;

    ul {
        width: 100%;
        height: 300px;
        margin-left: 6%;
        // margin: 10px auto;
        li {
            float: left;
            width: 23%;
            margin: 3% 0 0 3%;
            padding: 10px 20px;
            text-align: center;
            //letter-spacing: 2px;
            img {
                height: 90px;
                display: block;
                margin: 0 0 20px 37%;
            }

            p {
                //letter-spacing: 1px;
                text-indent: 2rem;
                line-height: 20px;
                color: #5e6d82;
            }

            strong {
                height: 60px;
                color: #000;
            }
        }
    }
}

// 平台特点
.iot_function {
    height: 2500px;
    padding: 30px 20%;
    position: relative;
    background: #ebeef5;

    ul {
        margin-top: 60px;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-bottom: 1px solid #ebeef5;

        .viewLeft,
        .viewRight {
            padding: 0px 5%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 200px;
                height: 120px;
                border-radius: 5px;
                display: block;
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15px;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

.introduce_leftImg {
    cursor: url('../../../../assets/img/other/zoom.png'), auto;
}

.el-descriptions {
    margin-top: 60px !important;
}

/* 小屏幕（平板，大于等于 768px） */
// @media only screen and (max-width:767px) and (min-width: 375px) {
// @media only screen and (max-width: 768px) {
@media (max-width: 768px) and (min-width: 391px) {
    .iot_container {
        margin: 100px auto;
        // font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem;
        font-weight: bold;
    }

    .iot_title {
        height: 110rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 11%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 17% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            // margin-top: 10rem;
            // display: none;
        }
    }

    //  产品简介
    .iot_introduce {
        height: 218.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 4.5rem;
            text-indent: 8rem;
            line-height: 200%;
        }

        img {
            width: 95.5rem;
            padding: 3rem 2rem;
        }
    }

    // 系统架构
    .iot_agreement {
        height: 130.25rem;
        padding: 0.875rem 4%;

        img {
            margin: 7.75rem 0 0 0;
            width: 96%;
            height: 98.75rem;
        }
    }

    // 配置性能
    .iot_platformConfiguration,
    .iot_platformPerformance {
        height: 62.75rem;
        padding: 0 4%;

        ul {
            margin-left: 2%;

            li {
                width: 31%;
                margin: 3% 0 0 1%;
                padding: 3.625rem 0.25rem;

                img {
                    height: 8.625rem;
                }

                p {
                    width: 31rem;
                    // 使连续数字字母强制换行。
                    word-wrap: break-word;
                    font-size: 2.8rem;
                    line-height: 6.25rem;
                    margin-top: 2.25rem;
                    // overflow: hidden;
                }

                strong {
                    font-size: 3rem;
                    // font-weight: bold;
                }
            }
        }
    }

    //  .iot_superiority{
    //       height: 90rem;
    //       padding: 1.875rem 6%;
    //       .el-descriptions {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //       /deep/.el-descriptions__title {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //   }
    // 平台特点
    .iot_function {
        height: 588rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 8rem;

            li {
                width: 100%;
                padding: 3.875rem 0%;
                // height: 60rem;
                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;
    }
}

@media only screen and (max-width: 490px) {
    .iot_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem !important;
        font-weight: bold;
    }

    .iot_title {
        height: 77rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 11%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 17% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            border-radius: 1.25rem;
            // margin-top: 10rem;
            // display: none;
        }
    }

    //  产品简介
    .iot_introduce {
        height: 218.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 4.5rem;
            text-indent: 8rem;
            line-height: 200%;
        }

        img {
            width: 95.5rem;
            padding: 3rem 2rem;
        }
    }

    // 系统架构
    .iot_agreement {
        height: 130.25rem;
        padding: 0.875rem 4%;

        img {
            margin: 7.75rem 0 0 0;
            width: 96%;
            height: 98.75rem;
        }
    }

    // 配置性能
    .iot_platformConfiguration,
    .iot_platformPerformance {
        height: 66.75rem;
        padding: 0 4%;

        ul {
            margin-left: 2%;

            li {
                width: 31%;
                margin: 3% 0 0 1%;
                padding: 3.625rem 0.25rem;

                img {
                    height: 8.625rem;
                }

                p {
                    width: 31rem;
                    // 使连续数字字母强制换行。
                    word-wrap: break-word;
                    font-size: 2.8rem;
                    line-height: 6.25rem;
                    margin-top: 2.25rem;
                    // overflow: hidden;
                }

                strong {
                    font-size: 3rem;
                    // font-weight: bold;
                }
            }
        }
    }

    //  .iot_superiority{
    //       height: 100rem;
    //       padding: 1.875rem 6%;
    //       .el-descriptions {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //       /deep/.el-descriptions__title {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //   }
    // 平台特点
    .iot_function {
        height: 588rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 8rem;

            li {
                width: 100%;
                padding: 3.875rem 0%;
                // height: 60rem;
                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;
    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 17rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }
}
</style>
