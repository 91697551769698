<template>
    <div class="immeditate_container">
        <!-- 标题部分 -->
        <div class="immeditateBackground">
            <div class="immeditate_title">
                <div class="address">
                    <h3 class="animated bounceInLeft">成都微见科技有限公司</h3>
                    <p class="animated bounceInLeft">地址：四川省成都市郫都区创智南一路66号绿地盈创2栋2209室</p>
                </div>

                <div>
                    <p class="animated bounceInLeft">手机：18628976347（郑先生）</p>
                    <p class="animated bounceInLeft">邮箱：zhengzhou@microknown.com</p>
<!--                    <p class="animated bounceInLeft">微信：</p>-->
<!--                    <img-->
<!--                        class="animated bounceInLeft"-->
<!--                        src="../../../../assets/img/logos/raoWechat.png"-->
<!--                        alt=""-->
<!--                    />-->
                </div>
            </div>
            <div class="immeditate_title1">
                <p class="animated bounceInLeft">手机：15858264060（唐先生）</p>
                <p class="animated bounceInLeft">邮箱：tangzhi@microknown.com</p>
<!--                <p class="animated bounceInLeft">微信：</p>-->
<!--                <img-->
<!--                    class="animated bounceInLeft"-->
<!--                    src="../../../../assets/img/logos/lengWechat.png"-->
<!--                    alt=""-->
<!--                />-->
            </div>
        </div>

        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart :emailPhoneInfo="emailPhone" :codeInfo="codeQR" />
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../../components/floatingWindow/floatingWindow.vue'

export default {
    components: {commonBottomPart, commonFloatWin},
    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            title: '',
            emailPhone: {},
            codeQR: []
        }
    },

    methods: {
        // 初始化基本信息
        initBaseInfoList() {
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        }
    }
}
</script>

<style lang="less" scoped>
.immeditate_container {
    position: relative;
}

.immeditateBackground {
    display: flex;
    height: 980px;
    width: 1920px;
    position: relative;
    background: url('../../../../assets/img/titlebackground/titleB31.jpg') no-repeat;
    background-size: cover;
}




.immeditate_title {
    color: white;
    padding: 9.5% 2%;
    text-align: center;
    margin:0 auto;
    margin-right: 3%;




    h3 {
        font-weight: 400;
        margin-bottom: 50px;
        margin-left: 11%;
        text-align: left;
    }

    p {
        text-align: left;
        line-height: 40px;
        margin-left: 10%;
        width: 260px;
    }

    img {
        width: 150px;
        height: 150px;
        margin-left: 30px;
        margin-top: -25px;
    }
}

.immeditate_title1 {
    color: white;
    padding: 12% 2%;
    text-align: center;
    margin: auto;
    margin-left: 3%;

    h3 {
        font-weight: 400;
        margin-bottom: 50px
    }

    p {
        text-align: left;
        line-height: 40px;
        margin-left: 14%;
        width: 260px;
    }

    img {
        width: 150px;
        height: 150px;
        margin-left: 45px;
        margin-top: -25px;
    }
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 1068px) {
    .immeditate_container {

        .immeditateBackground {
            display: flex;
            height: 185.25rem;
            width: 121rem;
            position: relative;
            flex-wrap: wrap;
            align-content: center;

            .immeditate_title {
                height: 35.5rem;
                width: 80%;
                padding: 34.5% 9%;
                .address{
                    margin-bottom: 9rem;
                }

                h3 {
                    //letter-spacing: 2.125rem;
                    font-weight: bold;
                    margin-bottom: 6.125rem;
                    font-size: 5rem;
                    margin-left: 26%;
                }

                p {
                    line-height: 6.5rem;
                    margin-left: 27%;
                    font-size: 3rem;
                    width: 51.25rem;
                    text-align: left;
                    //letter-spacing: 0.5rem;
                }

                img {
                    width: 17.5rem;
                    height: 17.5rem;
                    margin-left: -5.125rem;
                    margin-top: -4.5625rem;
                }
            }

            .immeditate_title1 {
                height: 68.5rem;
                width: 80%;
                padding: 2.5% 9%;

                h3 {
                    //letter-spacing: 2.125rem;
                    font-weight: bold;
                    margin-bottom: 6.125rem;
                    font-size: 5rem;
                }

                p {
                    line-height: 6.5rem;
                    margin-left: 23%;
                    font-size: 3rem;
                    width: 51.25rem;
                    text-align: left;
                    //letter-spacing: 0.5rem;
                }

                img {
                    width: 17.5rem;
                    height: 17.5rem;
                    margin-left: -12.1875rem;
                    margin-top: -4.5626rem;
                }
            }
        }
    }
}
</style>
