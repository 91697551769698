<template>
    <div class="configurationGateway_container">
        <!-- 标题部分 -->
        <div class="configurationGateway_title">
            <h3 class="animated bounceInLeft">「 {{ titleAreaData.text }} 」</h3>
            <p class="common_title_p animated bounceInRight">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_line animated bounceInLeft"></div>
            <small class="animated bounceInRight" @click="immediately">立即咨询</small>
        </div>

        <div class="container_architecture" v-for="(item, index) in produceList" :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2"
                    style="margin-top: 90px;font-weight:600;font-size:30px">
                    {{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon" />
            </div>
        </div>

        <div class="common_superiority" v-if="superiorityList.length>0">
            <h2 animated bounceInLeft class="main_catchword_h2">系统截图</h2>
            <ul>
                <li v-for="(item, index) in superiorityList" :key="item.id">
                    <div class="viewLeft">
                        <img :src="item.superiorityIcon" v-if="(index + 1) % 2 == 0" alt="" />
                        <strong v-if="(index + 1) % 2 != 0">{{ item.title }}</strong>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text }}</p>
                    </div>

                    <div class="viewRight">
                        <img :src="item.superiorityIcon" v-if="(index + 1) % 2 != 0" alt="" />
                        <strong v-if="(index + 1) % 2 == 0">{{ item.title }}</strong>
                        <p v-if="(index + 1) % 2 == 0">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>

        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart :emailPhoneInfo="emailPhone" :codeInfo="codeQR" />
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonProduct from '../../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../../components/bottomPart/bottomPart.vue'
import commonFloatWin from '../../../../components/floatingWindow/floatingWindow.vue'
import $ from 'jquery'

export default {
    inject: ['reload'],
    components: {commonProduct, commonBottomPart, commonFloatWin},

    mounted() {
        this.initBaseInfoList()
    },

    data() {
        return {
            titleAreaData: {
                text: '小工单生产管理',
                describe:
                    '生产透明，轻量、简单、高效，助力制造企业数字化转型第一步'
            },

            emailPhone: {},
            codeQR: [],

            produceList: [
                {
                    id: '1',
                    title: ' 小工单生产管理',
                    superiorityIcon: require('../../../../assets/img/product/application/task/produce.png')
                },
            ],

            superiorityList: [
                {
                    id: '1',
                    title: '生产指挥大屏',
                    text: '生产情况一目了然',
                    superiorityIcon: require('../../../../assets/img/product/application/task/xgd1.png'),
                },
                {
                    id: '2',
                    title: '工艺路线',
                    text: '工艺管理数字化，订单可以跟随工艺路线实时了解生产进度',
                    superiorityIcon: require('../../../../assets/img/product/application/task/xgd2.png'),
                },
                {
                    id: '3',
                    title: '现场操作平板HMI',
                    text: '生产车间采用工业平板，方便现场工人操作，了解作业规范、报工、事件上报等',
                    superiorityIcon: require('../../../../assets/img/product/application/task/xgd4.png'),
                },
            ]
        }
    },

    methods: {
        // 滚动监听 动画监听
        handleScroll(e) {
            let scrollHeight = e.target.scrollTop // 滚动条的滚动行程
            if (scrollHeight > 500) {
                $('.edge_agreement').removeClass('displayNone')
                $('.edge_agreement > h2').addClass('animated bounceInUp')
                $('.edge_agreement > div').addClass('animated bounceInUp')
            }
            if (scrollHeight > 1500) {
                $('.edge_superiority').removeClass('displayNone')
                $('.edge_superiority h2').addClass('animated bounceInUp')
                $('.edge_superiority > ul').addClass('animated bounceInLeft')
            }
        },

        // 初始化基本信息
        initBaseInfoList() {
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },

        // 立即咨询
        immediately() {
            this.reload()
            this.$router.push({name: 'Immediately'})
        },
    }
}
</script>

<style lang="less" scoped>
.common_superiority {
    // height: 1900px;
    width: 1282PX;
    margin: 50px auto;
    position: relative;

    ul {
        width: 100%;
        margin: 0 auto;
        margin-top: 30PX;
        //   border-radius: 10px;
        //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 10px;

        .viewLeft,
        .viewRight {
            padding: 0px 9%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 520PX;
                //   height: 300PX;
                border-radius: 5px;
                display: block;
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22PX;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15PX;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

.container_architecture {
    width: 1915PX;
    margin: 50px auto;

    img {
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.configurationGateway_container {
    color: #fff;
    position: relative;
    //   background: #ebeef5;
    background: #fff;
}

.line_top {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    margin: 20px 0 0 40%;
}

.line_bottom {
    width: 80px;
    height: 1px;
    background: #2db5a3;
    position: relative;
    top: 60px;
    left: 55%;
}

.main_catchword_h2 {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    color: #000;
    // margin-bottom: 20px;
    margin-top: 80PX;
}

.configurationGateway_title {
    height: 660px;
    background: url('../../../../assets/img/titlebackground/titleB54.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;

    h3 {
        margin: 0;
        font-size: 60px;
        line-height: 200%;
        font-weight: bold;
        padding-top: 200px;
    }

    .common_title_p {
        width: 50%;
        line-height: 200%;
        padding: 0 25%;
        font-size: 22px;
        //letter-spacing: 2px;
    }

    .title_line {
        width: 5%;
        height: 1px;
        background: #2db5a3;
        margin: 5% 0 0 64%;
    }

    small {
        font-size: 18px;
        position: absolute;
        top: 70%;
        left: 70%;
        cursor: pointer;
        padding: 10px 20px;
        background: #2db5a3;
        border-radius: 4px;
    }
}

.briefIntroduction {
    height: 820px;
    padding: 20px 20%;
    text-align: center;
    background: #fff;

    p {
        color: #333;
        font-size: 16px;
        text-align: left;
        text-indent: 2rem;
        line-height: 200%;
        //letter-spacing: 2px;
        margin-bottom: 20px;
    }

    .introduce_rightImg {
        width: 500px;
    }
}

// 配置
.configure_platformConfiguration {
    height: 450px;
    padding: 0 10%;

    ul {
        width: 100%;
        height: 350px;
        margin-left: 13%;

        li {
            float: left;
            width: 14%;
            height: 280px;
            margin: 3% 5px;
            // padding: 10px 30px;
            text-align: center;
            //letter-spacing: 2px;
            border-radius: 5px;
            border: 1px solid #ebeef5;
            box-shadow: 2px 2px 12px 0 rgba(0, 0, 0, 0.1);

            img {
                height: 100px;
                display: block;
                margin: 25px 0 20px 25%;
            }

            strong {
                height: 100px;
                color: #000;
            }
        }
    }
}

// 性能
.configure_platformPerformance {
    height: 430px;
    padding: 0 10%;

    ul {
        width: 100%;
        height: 350px;
        margin-left: 10%;

        li {
            float: left;
            width: 17%;
            margin: 3% 0 0 1%;
            padding: 10px;
            text-align: center;
            //letter-spacing: 2px;
            img {
                height: 90px;
                display: block;
                margin: 10px 0 30px 36%;
            }

            strong {
                height: 100px;
                color: #000;
            }
        }
    }
}

//产品功能
.configure_functionality {
    height: 2100px;
    padding: 30px 20%;
    position: relative;

    ul {
        margin-top: 80px;
        border-radius: 5px;
        border: 1px solid #ebeef5;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-bottom: 1px solid #ebeef5;

        .viewLeft,
        .viewRight {
            padding: 0px 10%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 120px;
                height: 100px;
                border-radius: 5px;
                display: block;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22px;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15px;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

.largeMonitor {
    height: 1100px;
    width: 90%;
    padding: 20px 5%;
    text-align: center;

    img {
        border: #2db5a3 1px solid;
        width: 30%;
        height: 300px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.phoneShow {
    height: 1000px;
    padding: 20px 20%;
    text-align: center;
    background: #ebeef5;

    img {
        height: 400px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.phoneShow {
    height: 600px;
    background: #fff;
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

.introduce_leftImg {
    cursor: url('../../../../assets/img/other/zoom.png'), auto;
}

/* 小屏幕（平板，大于等于 768px 大于390px） */
@media (max-width: 768px) and (min-width: 391px) {
    // @media only screen and (max-width: 768px) {
    .configurationGateway_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem;
        font-weight: bold;
    }

    .configurationGateway_title {
        height: 98rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 10%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 16.5% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            // display: none;
        }
    }

    //  产品简介
    .briefIntroduction {
        height: 200.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 4.5rem;
            text-indent: 8rem;
            line-height: 200%;
        }

        .introduce_rightImg {
            width: 70.25rem;
        }
    }

    //  // 配置性能
    //  .allocation{
    //       height: 160rem;
    //       padding: 1.875rem 6%;
    //       font-size: 3rem !important;
    //       .el-descriptions {
    //         margin-top: 5rem;
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //       /deep/.el-descriptions__title {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //   }
    // 配置性能
    // 配置
    .configure_platformConfiguration {
        height: 96.125rem;
        padding: 1% 2%;

        ul {
            margin-top: 9rem;
            margin-left: 2%;

            li {
                float: left;
                width: 31.5%;
                height: 36rem;
                margin: 0;

                img {
                    height: 9.25rem;
                    margin: 1.5625rem 0 1.25rem 34%;
                }

                strong {
                    word-wrap: break-word;
                    //  font-weight: bold;
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 性能
    .configure_platformPerformance {
        height: 80rem;
        padding: 1% 4%;

        ul {
            margin-top: 9rem;
            margin-left: 2%;

            li {
                float: left;
                width: 43%;
                height: 30.5rem;
                margin: 1rem 2rem;

                img {
                    height: 10.25rem;
                    margin: 1.5625rem 0 1.25rem 38%;
                }

                strong {
                    word-wrap: break-word;
                    //  font-weight: bold;
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 产品功能
    .configure_functionality {
        height: 506rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 9rem;

            li {
                width: 100%;
                padding: 3.875rem 0%;
                // height: 60rem;
                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 数字化大屏展示
    .largeMonitor {
        height: 236rem;
        padding: 3.875rem 5%;

        .main_catchword_h2 {
            padding-bottom: 6rem;
        }

        img {
            width: 48%;
            height: 39.75rem;
        }
    }

    // 手机端展示
    .phoneShow {
        height: 96rem;
        padding: 3.25rem 2%;

        img {
            margin-top: 10rem;
            height: 68rem;
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;

    }
}

// 最小型号（小于414px）
@media only screen and (max-width: 490px) {
    .configurationGateway_container {
        margin: 100px auto;
        font-size: 0.6375rem;
        // z-index: 2000px;
    }

    .line_top {
        width: 7rem;
        margin: 0.875rem 0 0 34%;
    }

    .line_bottom {
        width: 7rem;
        top: 13.375rem;
        left: 60%;
    }

    .main_catchword_h2 {
        font-size: 4.875rem !important;
        font-weight: bold;
    }

    .configurationGateway_title {
        height: 98rem;

        h3 {
            font-size: 6.75rem;
            padding-top: 28.5rem;
        }

        .common_title_p {
            width: 83%;
            padding: 4px 10%;
            font-size: 4.375rem;
        }

        .title_line {
            width: 7%;
            height: 0.0625rem;
            margin: 16.5% 0 0 62%;
            display: none;
        }

        small {
            font-size: 4.125rem;
            // display: none;
            border-radius: 1.25rem;
        }
    }

    //  产品简介
    .briefIntroduction {
        height: 200.25rem;
        padding: 1.25rem 9%;

        p {
            font-size: 4.5rem;
            text-indent: 8rem;
            line-height: 200%;
        }

        .introduce_rightImg {
            width: 70.25rem;
        }
    }

    // 配置性能
    //  .allocation{
    //       height: 206rem;
    //       padding: 1.875rem 6%;
    //       font-size: 3rem !important;
    //       .el-descriptions {
    //         margin-top: 5rem;
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //       /deep/.el-descriptions__title {
    //         font-size: 3rem;
    //         font-weight: 700;
    //       }
    //   }
    // 配置性能
    // 配置
    .configure_platformConfiguration {
        height: 116.125rem;
        padding: 1% 2%;

        ul {
            margin-top: 9rem;
            margin-left: 2%;

            li {
                float: left;
                width: 31.5%;
                height: 44.5rem;
                margin: 0;

                img {
                    height: 10.25rem;
                    margin: 1.5625rem 0 1.25rem 34%;
                }

                strong {
                    word-wrap: break-word;
                    //  font-weight: bold;
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 性能
    .configure_platformPerformance {
        height: 90rem;
        padding: 1% 4%;

        ul {
            margin-top: 9rem;
            margin-left: 2%;

            li {
                float: left;
                width: 43%;
                height: 36.5rem;
                margin: 1rem 2rem;

                img {
                    height: 10.25rem;
                    margin: 1.5625rem 0 1.25rem 38%;
                }

                strong {
                    word-wrap: break-word;
                    //  font-weight: bold;
                    font-size: 2.8rem;
                }
            }
        }
    }

    // 产品功能
    .configure_functionality {
        height: 506rem;
        padding: 3.875rem 5%;

        ul {
            height: 170rem;
            width: 100%;
            margin-top: 9rem;

            li {
                width: 100%;
                padding: 3.875rem 0%;
                // height: 60rem;
                .viewLeft,
                .viewRight {
                    padding: 0 2%;

                    img {
                        width: 31.75rem;
                        height: 18.5rem;
                        border-radius: -1.6875rem;
                        padding: 9rem 1rem;
                    }

                    strong {
                        font-weight: bold;
                        font-size: 4.375rem;
                    }

                    p {
                        text-indent: 0rem;
                        line-height: 4.625rem;
                        font-size: 3rem;
                    }
                }
            }
        }
    }

    // 数字化大屏展示
    .largeMonitor {
        height: 236rem;
        padding: 3.875rem 5%;

        .main_catchword_h2 {
            padding-bottom: 6rem;
        }

        img {
            width: 48%;
            height: 39.75rem;
        }
    }

    // 手机端展示
    .phoneShow {
        height: 96rem;
        padding: 3.25rem 2%;

        img {
            margin-top: 10rem;
            height: 68rem;
        }
    }

    // 相关产品推荐
    .productRecommendation {
        height: 180.75rem;

    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 14rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }

    .common_superiority {
    // height: 1900px;
        width: 95%;
        margin: 50px auto;
        position: relative;

        ul {
            width: 100%;
            margin: 0 auto;
            margin-top: 30px;
            //   border-radius: 10px;
            //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }

        ul li {
            width: 90%;
            margin-right: 1%;
            z-index: 99;
            padding: 30px 5%;
            background: #fff;
            display: flex;
            border-radius: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            margin-top: 10px;

            .viewLeft,
            .viewRight {
                padding: 0px 9%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                img {
                    width: 520px;
                    //   height: 300PX;
                    border-radius: 5px;
                    display: block;
                    justify-content: space-between;
                }

                strong {
                    color: #333;
                    font-weight: 400;
                    margin-top: 1.6rem;
                    font-size: 3.3rem;
                    //letter-spacing: 2px;
                }

                p {
                    font-size: 2.3rem;
                    text-indent: 2rem;
                    line-height: 3.6rem;
                    //letter-spacing: 2px;
                    color: #5e6d82;
                }
            }
        }
    }
}
</style>
