<template>
    <div class="wechatBox" :style="{ height: viewHeight + 'px' }">
        <p>请使用微信扫描二维码进行绑定</p>
        <wxlogin
            :appid="appid"
            :scope="scope"
            :redirect_uri="encodeURIComponent(redirectUrl)"
            :href="base64css"
            :theme="'black'"
        ></wxlogin>
    </div>
</template>
<script>
import demand from '@/api/data.js'
import wxlogin from 'vue-wxlogin'

export default {
    mounted() {
        this.initGetAppid()
        let wechatType = this.$store.state.user.jumpWechat
        if (wechatType == null) {
            this.$router.go(-1)
        } else {
            this.redirectUrl = (wechatType == 0) ? 'https://www.kmmyun.com/officalvue/#/baseInfo' : 'https://www.kmmyun.com/officalvue/#/adminPersonalInfo'
        }
        this.viewHeight = document.body.offsetHeight
    },
    components: {wxlogin},
    data() {
        return {
            viewHeight: 0,
            appid: null,
            scope: 'snsapi_login',
            base64css:
                'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICAgIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgICB3aWR0aDogMjAwcHg7CiAgICBtYXJnaW4tdG9wOiAxcHg7Cn0KI3d4X2RlZmF1bHRfdGlwIHsKZGlzcGxheTogbm9uZTsKfQp9',
            redirectUrl: null
        }
    },
    methods: {
        // 获取调用微信接口参数
        initGetAppid() {
            let tempData = {
                parType: 'scanLogin'
            }
            demand.home.getWechatParamer(tempData).then((res) => {
                if (res.code == 1) {
                    this.appid = res.object.appId
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.wechatBox {
    width: 100%;
    background: #0000007a;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        width: 220px;
        color: #e2e2e2;
        font-size: 14px;
        text-align: center;
        padding: 10px 8px;
        border-radius: 30px;
        margin-bottom: 20px;
        //letter-spacing: 1px;
        background: #0000006a;
    }
}
</style>
