<template>
    <div class="aboutUs_container">
        <!-- 标题部分 -->
        <div class="company_title">
            <h3>{{ titleAreaData.text }}</h3>
            <p>
                {{ titleAreaData.describe }}
            </p>
            <div class="title_connect">
                <div class="title_line animated bounceInLeft"></div>
                <small class="animated bounceInRight" @click="immediately()"
                ><span>立即咨询</span></small>
                <!-- </div> -->
            </div>
        </div>

        <!-- 企业简介 -->
        <div class="company_info">
            <div class="info_header">
                <div class="info_rightText">
                    <h2>{{ companyInfoList.title }}</h2>
                    <p v-for="item in companyInfoList.info" :key="item.id">
                        {{ item.text }}
                    </p>
                </div>
                
                <div class="info_leftImg">
                    <img :src="companyInfoList.imgPath" alt=""/>
                </div>
            </div>
        </div>

        <!-- 企业文化 -->
<!--        <div class="company_culture">-->
<!--            <div class="culture_header">-->
<!--                <div class="culture_lfetText">-->
<!--                    <h2>{{ companyCultureList.title }}</h2>-->
<!--                    <p v-for="item in companyCultureList.text" :key="item.id">-->
<!--                        {{ item.content }}-->
<!--                    </p>-->
<!--                </div>-->
<!--                <div class="culture_rightImg">-->
<!--                    <img :src="companyCultureList.imgPath" alt=""/>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="culture_bottom">-->
<!--                <ul>-->
<!--                    <li v-for="item in companyCultureList.infoList"-->
<!--                        :key="item.id">-->
<!--                        <small>{{ item.id }}</small>-->
<!--                        <div class="culture_title">-->
<!--                            <p>{{ item.title }}</p>-->
<!--                            <h2>{{ item.remark }}</h2>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; 我们能做什么？ &ndash;&gt;-->
<!--        <div class="company_do">-->
<!--            <div class="do_header">-->
<!--                <div class="do_leftImg">-->
<!--                    <img :src="companyDoList.imgPath" alt=""/>-->
<!--                </div>-->
<!--                <div class="do_rightText">-->
<!--                    <h2>{{ companyDoList.title }}</h2>-->
<!--                    <div class="do_content" v-for="item in companyDoList.text"-->
<!--                         :key="item.id">-->
<!--                        <img :src="item.doIcon"/>-->
<!--                        <p>-->
<!--                            {{ item.content }}-->
<!--                        </p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

        <!-- 企业资质 -->
        <div class="company_certification">
            <h2>知识产权</h2>
            <img :src="companyCertificationList.imgPath" alt=""/>
        </div>


        <!-- 内容 第六部分 -->
        <div class="homeBottomPart">
            <common-BottomPart
                :emailPhoneInfo="emailPhone"
                :codeInfo="codeQR"
            ></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>
<script>
import commonBottomPart from '../../../components/bottomPart/bottomPart.vue'
import commonFloatWin
    from '../../../components/floatingWindow/floatingWindow.vue'
import demand from '@/api/data.js'
import $ from 'jquery'

export default {
    /*
    param title 标题
    param companyInfo 企业简介
    param companyCulture 企业文化
    param  companyDo  做什么
    param companyCertification 公司资质
    */
    components: {commonBottomPart, commonFloatWin},
    mounted() {
        // this.initData()
        this.initBaseInfoList()
    },
    data() {
        return {
            titleAreaData: {
                text: '关于我们',
                describe: '致力于制造行业数字化转型'
            },
            companyInfoList: {
                title: '企业简介',
                info: [
                    {
                        id: '1',
                        text: '成都微见科技有限公司成立于2019年，是一家致力于制造行业数字化转型的工业互联网企业，为制造企业提供采购、生产、质检、库存、销售、设备管理、物联网、大数据处理等一体化数字平台。',
                    },
                    {
                        id: '2',
                        text: '对客户：分工、协作（内部协作、外部协作);对公司：技术、业务咨询',
                    },
                    {
                        id: '3',
                        text: '对公司：技术、业务咨询',
                    },
                    {
                        id: '4',
                        text: '整个业务应用基于低代码，可以根据自身业务情况，选择合适的模块和应用，并进行个性化配置。基于低代码和大数据技术构建的工业互联网平台。',
                    }
                ],
                imgPath: require('../../../assets/img/aboutus/companyInfo4.png'),

            },
            companyCultureList: {
                title: '企业文化',
                text: [
                    {
                        id: '11',
                        content: '企业理念：做一家技术型驱动的创新型科技企业。'
                    },
                    {
                        id: '12',
                        content: '企业理念：做一家技术型驱动的创新型科技企业。'
                    },
                    {
                        id: '13',
                        content: '企业理念：做一家技术型驱动的创新型科技企业。'
                    },
                    {
                        id: '14',
                        content: '企业理念：做一家技术型驱动的创新型科技企业。'
                    }
                ],
                imgPath: require('../../../assets/img/aboutus/team.svg'),
                infoList: [
                    {
                        id: '1',
                        title: '企业使命',
                        remark: '助力企业快速自建工业互联网平台'
                    },
                    {
                        id: '2',
                        title: '企业使命',
                        remark: '助力企业快速自建工业互联网平台'
                    },
                    {
                        id: '3',
                        title: '企业使命',
                        remark: '助力企业快速自建工业互联网平台'
                    },
                    {
                        id: '4',
                        title: '企业使命',
                        remark: '助力企业快速自建工业互联网平台'
                    }
                ]
            },

            companyDoList: {
                title: '我们能做什么？',
                text: [
                    {
                        id: '22',
                        doIcon: require('../../../assets/img/icon/icon_05.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    },
                    {
                        id: '23',
                        doIcon: require('../../../assets/img/icon/icon_07.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    },
                    {
                        id: '24',
                        doIcon: require('../../../assets/img/icon/icon_09.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    },
                    {
                        id: '25',
                        doIcon: require('../../../assets/img/icon/icon_15.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    },
                    {
                        id: '26',
                        doIcon: require('../../../assets/img/icon/icon_20.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    },
                    {
                        id: '27',
                        doIcon: require('../../../assets/img/icon/icon_22.png'),
                        content: ' 行业支撑：提供各类行业应用解决方案。'
                    }
                ],
                imgPath: require('../../../assets/img/aboutus/do3.jpg')
            },
            companyCertificationList: {
                imgPath: require('../../../assets/img/aboutus/certification.png')
            },
            emailPhone: {},
            codeQR: []
        }
    },
    methods: {
        //初始化基本信息
        initBaseInfoList() {
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },

        immediately() {
            this.$router.push({name: 'Immediately'})
        }
    }
}
</script>
<style lang="less" scoped>
.aboutUs_container {
    color: black;
    position: relative;
    background: #fff;
}
.title_line {
    width: 5%;
    height: 1px;
    background: #2db5a3;
    margin: 7.3% 0 0 61%;
}

small {
    font-size: 18px;
    position: absolute;
    top: 70%;
    left: 67%;
    cursor: pointer;
    padding: 10px 20px;
    background: #2db5a3;
    border-radius: 4px;
}

.company_title {
    color: white;
    height: 660px;
    background: url('../../../assets/img/titlebackground/titleB27.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
    margin: 0;

    h3 {
        margin: 0;
        line-height: 200%;
        padding-top: 200px;
        font-size: 40px;
        font-weight: normal;
    }

    p {
        width: 50%;
        line-height: 200%;
        padding: 0 25%;
        font-size: 22px;
        //letter-spacing: 2px;
    }
}

//图片透明 文字不变???

.homeBottomPart {
    height: 420PX;
}

// 公司信息(简介)
// .company_info {
// height: 260px;
// // width: 1000px;
// margin: 0 auto;
// padding: 40px 15%;
// // background: linear-gradient(
// //   45deg,
// //   rgba(78, 176, 233, 0.1),
// //   rgba(199, 121, 208, 0.2),
// //   rgba(75, 192, 200, 0.3)
// // );

// p {
//   text-align: left;
//   color: #666666;
//   font-size: 18px;
//   line-height: 40px;
//   text-indent: 2em;
//   //letter-spacing: 2px;
//   i {
//     font-size: 30px;
//     color: rgb(133, 155, 163);
//   }
// }
// }
// 公司简介
.company_info {
    height: 540px;
    width: 1413PX;
    margin: 10px auto;

    .info_header {
        width: 85%;
        height: 500PX;
        margin: 20PX 100PX;

        .info_leftImg {
            width: 45%;
            float: left;
            text-align: center;
            padding-top: 60PX;
            // padding-left: 60px;
            img {
                width: 560PX;
                height: 400PX;
            }
        }

        .info_rightText {
            width: 45%;
            float: right;
            padding: -10px 80px 0px 80px;
            position: relative;

            h2 {
                // position: absolute;
                // top: 2%;
                left: 11%;
                color: darkgrey;
                font-size: 30PX;
                //letter-spacing: 8px;
                text-shadow: 0px 2px 2px #e6e6e6;
            }

            p {
                color: #666666;
                font-size: 16PX;
                line-height: 40px;
                text-indent: 2em;
                //letter-spacing: 2px;
                // margin: 60px 100px;
                // margin-left: 10px;
            }
        }
    }
}

//企业文化
.company_culture {
    height: 600PX;
    width: 1413PX;
    margin: 10px auto;
    // width: 100%;
    position: relative;

    .culture_header {
        height: 400PX;
        padding: 100PX auto;

        .culture_lfetText {
            width: 45%;
            float: left;
            padding: 50PX;
            padding-top: 80PX;
        }

        .culture_rightImg {
            width: 45%;
            float: right;
        }

        h2 {
            position: absolute;
            top: 2%;
            left: 11%;
            color: darkgrey;
            font-size: 30PX;
            //letter-spacing: 8px;
            text-shadow: 0px 2px 2px #e6e6e6;
        }

        p {
            color: #666666;
            font-size: 16PX;
            line-height: 40PX;
            text-indent: 2em;
            //letter-spacing: 2px;
            // margin: 60px 100px;
            margin-left: 100PX;
        }

        img {
            width: 500PX;
            height: 400PX;
        }
    }

    .culture_bottom {
        height: 160PX;
        width: 100%;
        margin: 0 auto;
        position: relative;
        // display: block;
        ul {
            height: 140PX;
            width: 100%;
            list-style-type: none;
            float: left;
            margin-right: 0 10%;
            margin: 20PX 150PX;
            // width: 100%;
            li {
                // float: left;
                // height: 200px;
                display: inline-block;
                box-shadow: #e6e6e6 0px 0px 15px 5px;
                margin-right: 15PX;
                background-color: #ffffff;
                margin-bottom: 10PX;
                width: 20%;
                border-radius: 2PX;
                padding: 0 auto;
            }

            // 小图标样式
            small {
                font-size: 36PX;
                font-style: italic;
                color: #2db5a3;
                text-shadow: 0px 1px 0px #455574, 0px 2px 0px #b0b0b0,
                0px 3px 0px #a0a0a0, 0px 4px 0px #909090,
                0px 5px 10px rgb(98 211 226 / 90%);
                width: 40PX;
                height: 60PX;
                line-height: 90PX;
                margin-right: 10PX;
                float: left;
                text-align: center;
            }

            h2 {
                color: #666666;
                font-size: 13PX;
                //letter-spacing: 1px;
                // font-weight: 400;
            }

            p {
                color: #777;
                margin-top: 10PX;
                font-size: 20PX;
                // line-height: 1.75;
            }

            .culture-title {
                margin-top: 20PX;
                display: inline-block;
                height: 60PX;
                line-height: 60PX;
                box-sizing: border-box;
            }
        }
    }
}

//我们能做什么
.company_do {
    //   height: 450px;
    width: 1500PX;
    margin: 10PX auto;
    // width: 100%;
    position: relative;

    .do_header {
        // height: 420PX;
        padding: 10PX auto;

        .do_rightText {
            width: 40%;
            float: right;
            padding: 80PX 60PX 80PX 0PX;

            .do_content {
                // display: inline;
                img {
                    width: 50PX;
                    height: 50PX;
                    float: left;
                    //  display: block;
                }

                p {
                    // width: 84%;
                }
            }
        }

        .do_leftImg {
            width: 43%;
            float: left;
            text-align: center;
            padding-left: 10PX;
            padding-top: 50PX;
        }

        h2 {
            position: absolute;
            top: 2%;
            right: 29.5%;
            color: darkgrey;
            font-size: 30PX;
            //letter-spacing: 8px;
            text-shadow: 0PX 2PX 2PX #e6e6e6;
            margin-bottom: 20px;
        }

        p {
            color: #666666;
            font-size: 16PX;
            line-height: 40PX;
            text-indent: 2em;
            //letter-spacing: 2px;
            // margin: 60px 100px;
            margin-left: 100PX;
        }

        img {
            width: 576PX;
            height: 420PX;
            margin-left: 156PX;
        }
    }
}

//公司认证
.company_certification {
    width: 1282PX;
    padding: 50PX 13%;
    margin: auto;

    h2 {
        position: absolute;
        //   top: 59%;
        //   right: 75.5%;
        color: darkgrey;
        font-size: 30PX;
        //letter-spacing: 8px;
        text-shadow: 0PX 2PX 2PX #e6e6e6;
    }

    img {
        // height: 680PX;
        width: 100%;
        margin: 90PX 30px 40px 0;
        cursor: pointer;
    }
}

/* 小屏幕（平板，大于等于 768px） */
@media (max-width: 768px) {
    .company_info {
        height: 540px;

        .info_header {
            width: 85%;
            height: 500px;
            margin: 20px 100px;

            .info_leftImg {
                width: 45%;
                float: left;
                text-align: center;
                padding-top: 11.75rem !important;
                padding-left: 22rem !important;
                // padding-left: 60px;
                img {
                    width: 560px;
                    height: 400px;
                }
            }

            .info_rightText {
                width: 100% !important;
                float: right;
                padding: -10px 80px 0px 80px;
                position: relative;

                h2 {
                    // position: absolute;
                    // top: 2%;
                    left: 11%;
                    color: darkgrey;
                    font-size: 30px;
                    //letter-spacing: 8px;
                    text-shadow: 0px 2px 2px #e6e6e6;
                }

                p {
                    color: #666666;
                    font-size: 16px;
                    line-height: 40px;
                    text-indent: 2em;
                    //letter-spacing: 2px;
                    // margin: 60px 100px;
                    // margin-left: 10px;
                }
            }
        }
    }

    .company_culture {
        height: 600px;
        // width: 100%;
        position: relative;

        .culture_header {
            height: 400px;
            padding: 100px auto;

            .culture_lfetText {
                width: 45%;
                float: left;
                padding: 80px;
            }

            .culture_rightImg {
                width: 45%;
                float: right;
            }

            h2 {
                position: absolute;
                top: 2%;
                left: 11%;
                color: darkgrey;
                font-size: 30px;
                //letter-spacing: 8px;
                text-shadow: 0px 2px 2px #e6e6e6;
            }

            p {
                color: #666666;
                font-size: 16px;
                line-height: 40px;
                text-indent: 2em;
                //letter-spacing: 2px;
                // margin: 60px 100px;
                margin-left: 100px;
            }

            img {
                width: 500px;
                height: 400px;
            }
        }

        .culture_bottom {
            height: 160px;
            width: 100%;
            margin: 0 auto;
            position: relative;
            // display: block;
            ul {
                height: 140px;
                width: 100%;
                list-style-type: none;
                float: left;
                margin-right: 0 10%;
                margin: 20px 150px;
                // width: 100%;
                li {
                    // float: left;
                    // height: 200px;
                    display: inline-block;
                    box-shadow: #e6e6e6 0px 0px 15px 5px;
                    margin-right: 15px;
                    background-color: #ffffff;
                    margin-bottom: 10px;
                    width: 20%;
                    border-radius: 2px;
                    padding: 0 auto;
                }

                // 小图标样式
                small {
                    font-size: 36px;
                    font-style: italic;
                    color: #2db5a3;
                    text-shadow: 0px 1px 0px #455574, 0px 2px 0px #b0b0b0,
                    0px 3px 0px #a0a0a0, 0px 4px 0px #909090,
                    0px 5px 10px rgb(98 211 226 / 90%);
                    width: 40px;
                    height: 60px;
                    line-height: 90px;
                    margin-right: 10px;
                    float: left;
                    text-align: center;
                }

                h2 {
                    color: #666666;
                    font-size: 14px;
                    //letter-spacing: 1px;
                    // font-weight: 400;
                }

                p {
                    color: #777;
                    margin-top: 10px;
                    font-size: 20px;
                    // line-height: 1.75;
                }

                .culture-title {
                    margin-top: 20px;
                    display: inline-block;
                    height: 60px;
                    line-height: 60px;
                    box-sizing: border-box;
                }
            }
        }
    }

    .aboutUs_container {
        margin: 100px auto;
        // z-index: 2000px;
    }

    .company_title {
        height: 1200px;

        h3 {
            font-size: 100px;
            padding-top: 26.5rem;
        }
    }

    .company_title p {
        width: 92%;
        padding: 2.25rem 4%;
        font-size: 3.375rem;
    }

    // 公司简介
    .company_info {
        height: 128rem;
        width: 90%;

        .info_header {
            height: 156rem;
            width: 100%;
            // height: 66.25rem;
            margin: 1.25rem 0.25rem;

            .info_leftImg {
                padding: 0 32.25rem;

                img {
                    // float: right;
                    width: 58rem;
                    height: 32rem;
                }
            }

            .info_rightText {
                width: 90%;

                h2 {
                    text-align: center;
                    font-size: 6.875rem;
                }

                p {
                    font-size: 3rem;
                    line-height: 7.5rem;
                }
            }
        }
    }

    // 企业文化
    .company_culture {
        height: 128rem;

        .culture_header {
            .culture_lfetText {
                width: 94%;
                padding: 6rem;
                height: 30rem;

                h2 {
                    left: 40%;
                    font-size: 6.875rem;
                    //  bottom: 100px ;
                    top: -7%;
                }

                p {
                    font-size: 3rem;
                    line-height: 6.5rem;
                    text-indent: 0em;
                    //letter-spacing: 0.125rem ;
                    margin-left: 8.25rem;
                }
            }

            .culture_rightImg {
                width: 69%;

                img {
                    width: 51.25rem;
                    height: 32rem;
                }
            }
        }

        .culture_bottom {
            ul {
                margin: 1.25rem 5.375rem;

                li {
                    width: 45%;

                    small {
                        font-size: 6.25rem;
                        width: 5.5rem;
                    }

                    .culture_title {
                        h2 {
                            font-size: 2.875rem;
                        }
                    }

                    p {
                        font-size: 3.75rem;
                    }
                }
            }
        }
    }

    //做什么？
    .company_do {
        height: 144.125rem;

        .do_header {
            height: 140.125rem;

            .do_rightText {
                width: 95%;

                h2 {
                    top: -11%;
                    right: 25.5%;
                    font-size: 6.875rem;
                }

                .do_content {
                    img {
                        width: 9.125rem;
                        height: 8.125rem;
                        margin-left: 10.5rem;
                    }

                    p {
                        font-size: 3rem;
                        line-height: 7.5rem;
                        text-indent: 0em;
                        //letter-spacing: 0.125rem ;
                        margin-left: 8.25rem;
                    }
                }
            }
        }

        .do_leftImg {
            img {
                width: 55rem;
                height: 35rem;
                margin: 1rem 35rem;
            }
        }
    }

    // 公司资质
    .company_certification {
        padding: 0 5%;
        width: 92%;

        img {
            height: 59.5rem;
        }
    }
    .title_connect {
            // width: 110rem;
            position: relative;
            display: flex;

            .title_line {
                width: 10rem;
                height: 0.0625rem;
                margin: 10% 0 0 58%;
                display: none;
            }

            small {
                // width: 30rem;
                font-size: 4.125rem;
                border-radius: 1.25rem;
                top: 56%;
                // display: none;

                span {
                    font-size: 4.125rem;
                    font-weight: 300;
                }
            }
        }
}
</style>
