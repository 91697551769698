<template>
    <div class="cooperativePartner_container">
        <!-- 标题部分 -->
        <div class="cooperativePartner_title">
            <!-- 文字内容 -->
            <!-- <div class="cooperativePartner_title_info"> -->
            <h3>{{ titleAreaData.title }}</h3>
            <p class="common_title_p">
                {{ titleAreaData.describe }}
            </p>
            <div class="title_connect">
                <div class="title_line animated bounceInLeft"></div>
                <small class="animated bounceInRight" @click="immediately()"
                ><span>立即咨询</span></small>
                <!-- </div> -->
            </div>
        </div>

        <!--为什么成为KMM云业云平台合作伙伴  优势 -->
        <!-- <div class="cooperativePartner_superiority">
          <h2 class="main_catchword_h2 animated bounceInLeft">
            为什么成为成都微见合作伙伴
          </h2>
          <ul>
            <li v-for="item in superiorityList" :key="item.id">
              <img :src="item.img" alt="" />
              <strong>{{ item.title }}</strong>
              <p>{{ item.text }}</p>
            </li>
          </ul>
        </div> -->
        <!-- 优秀合作伙伴 -->
        <!-- <div class="cooperativePartner">
          <h2 class="main_catchword_h2 animated bounceInLeft">优秀的合作伙伴</h2>
          <div class="cooperativePartner_container">
            <ul
              class="cooperativePartner-list"
              v-for="item in cooperative_PartnerList"
              :key="item.id"
            >
              <li class="cooperativePartner-item">
                <img :src="item.imgPath" />
              </li>
            </ul>
          </div>
        </div> -->
        <!-- 相关产品推荐 -->
        <!-- <div class="productRecommendation">
          <common-product :productResourch="productList"></common-product>
        </div> -->
        <!-- 内容 第六部分 -->
        <!-- 案例展示 -->
        <div class="container_architecture" v-for="(item, index) in lowCodeList"
             :key="item.id">
            <div class="cooperativeUser_title">
                <h2 class="main_catchword_h2"
                    style="margin-top: 70px;font-weight:600;font-size:30px">
                    {{ item.title }}</h2>
            </div>
            <div style=" display: flex; justify-content: center;">
                <img :src="item.superiorityIcon">
            </div>
        </div>
        <!--优势展示  -->
        <div class="common_superiority" v-if="superiorityList.length>0">

            <h2 animated bounceInLeft class="main_catchword_h2">系统截图</h2>
            <ul>
                <li v-for="(item, index) in superiorityList" :key="item.id">
                    <div class="viewLeft">
                        <img :src="item.superiorityIcon"
                             v-if="(index + 1) % 2 == 0"
                             alt=""/>
                        <strong v-if="(index + 1) % 2 != 0">{{
                                item.title
                            }}</strong>
                        <p v-if="(index + 1) % 2 != 0">{{ item.text }}</p>
                    </div>
                    <div class="viewRight">
                        <img :src="item.superiorityIcon"
                             v-if="(index + 1) % 2 != 0"
                             alt=""/>
                        <strong v-if="(index + 1) % 2 == 0">{{
                                item.title
                            }}</strong>
                        <p v-if="(index + 1) % 2 == 0">{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="homeBottomPart">
            <common-BottomPart
                :emailPhoneInfo="emailPhone"
                :codeInfo="codeQR"
            ></common-BottomPart>
        </div>
        <!-- 左侧悬浮 -->
        <div class="floatingWindow">
            <common-floatWin></common-floatWin>
        </div>
    </div>
</template>

<script>
import commonProduct
    from '../../../components/productRecommendation/recommendation.vue'
import commonBottomPart from '../../../components/bottomPart/bottomPart.vue'
import commonFloatWin
    from '../../../components/floatingWindow/floatingWindow.vue'
import demand from '../../../api/data.js'
import $ from 'jquery'

export default {
    /*
      param title 标题
      param superiority 原因
      param cooperative_Partner 合作伙伴
      */
    components: {commonProduct, commonBottomPart, commonFloatWin},
    mounted() {
        this.initBaseInfoList()
        // this.initCooperativePartnerData()
    },
    data() {
        return {
            titleAreaData: {
                title: 'x集团日常办公管理',
                describe: '集团化流程协作，提高办公效率，高效数据统计'
            },

            lowCodeList: [
                {
                    id: '1',
                    title: 'OA',
                    superiorityIcon: require('../../../assets/img/product/application/oa/oa3.png')
                },
            ],

            superiorityList: [
                {
                    id: '1',
                    title: '集团化后台管理',
                    text: '全面梳理整个集团的日常办公流程，通过低代码平台配置各种业务单据，配置打印模板，审批流程，并配置各种所需的分析报表',
                    superiorityIcon: require('../../../assets/img/product/application/oa/oa1.png'),
                },
                {
                    id: '2',
                    title: '自定义复杂APP',
                    text: '移动办公审批，移动分析报表查看',
                    superiorityIcon: require('../../../assets/img/product/application/oa/oa2.png'),

                },
            ],

            productList: [],
            emailPhone: {},
            codeQR: []
        }
    },
    methods: {
        // 初始化基本信息
        initBaseInfoList() {
            this.productList = this.$store.state.pubInfo.recommendList
            this.emailPhone = this.$store.state.pubInfo.baseInfo
            this.codeQR = this.$store.state.pubInfo.codeList
        },
        // 立即咨询
        immediately() {
            this.$router.push({name: 'Immediately'})
        }
    }
}
</script>

<style lang="less" scoped>
.common_superiority {
    width: 1282PX;
    margin: 50px auto;
    position: relative;

    ul {
        width: 100%;
        margin: 0 auto;
        margin-top: 80px;
        //   border-radius: 10px;
        //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }

    ul li {
        width: 90%;
        margin-right: 1%;
        z-index: 99;
        padding: 30px 5%;
        background: #fff;
        display: flex;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        margin-top: 10px;

        .viewLeft,
        .viewRight {
            padding: 0px 9%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            img {
                width: 520PX;
                height: 350PX;
                object-fit: contain;
                border-radius: 5px;
                display: block;
                justify-content: space-between;
            }

            strong {
                color: #333;
                font-weight: 400;
                margin-top: 10px;
                font-size: 22PX;
                //letter-spacing: 2px;
            }

            p {
                font-size: 15PX;
                text-indent: 2rem;
                line-height: 26px;
                //letter-spacing: 2px;
                color: #5e6d82;
            }
        }
    }
}

.container_architecture {
    //   height: 840PX;
    width: 1915PX;
    margin: 50px auto;

    img {
        // height:730PX;
        width: 1282PX;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
}

.main_catchword_h2 {
    font-size: 30PX;
    font-weight: 600;
    text-align: center;
    color: #000;
    margin-bottom: 70px;
    margin-top: 80PX;
}

.cooperativePartner_container {
    color: #fff;
    position: relative;
    background: #fff;
}

.cooperativePartner_title {
    color: #fff;
    height: 660px;
    background: url('../../../assets/img/titlebackground/titleB30.jpg') no-repeat;
    background-size: cover;
    text-align: center;
    position: relative;
}

h3 {
    margin: 0;
    font-size: 60px;
    line-height: 200%;
    // font-weight: bold;
    padding-top: 200px;
    font-size: 40px;
    font-weight: normal;
}

.common_title_p {
    width: 50%;
    line-height: 200%;
    padding: 0 25%;
    font-size: 22px;
    //letter-spacing: 2px;
}

.title_line {
    width: 5%;
    height: 1px;
    background: #2db5a3;
    margin: 7.3% 0 0 61%;
}

small {
    font-size: 18px;
    position: absolute;
    top: 70%;
    left: 67%;
    cursor: pointer;
    padding: 10px 20px;
    background: #2db5a3;
    border-radius: 4px;
}

span {
    display: inline-block;
    font-size: 18px;
    color: #fff;
    // background-color: royalblue;
    // border: 1px solid #c4c27e;
    // padding: 6px 20px;
    // margin: 36px 0 0 0;
}

//优势
.cooperativePartner_superiority {
    position: relative;
    height: 500px;
    padding: 30px 15%;
    text-align: center;

    .main_catchword_h2 {
        font-size: 28px;
        font-weight: 400;
        //letter-spacing: 10px;
        color: #666;
        //letter-spacing: 0.125rem;
    }

    ul {
        color: black;
        padding: 100px auto;
        margin-left: 100px;

        li {
            width: 21%;
            height: 240px;
            float: left;
            padding: 5px 10px;
            text-align: center;
            margin-top: 80px;
            margin-right: 5px;
            z-index: 99;
            border-radius: 5px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

            img {
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 0 20px 38%;
            }

            strong {
                font-size: 20px;
            }

            p {
                color: #666666;
            }
        }
    }
}

//合作伙伴
.cooperativePartner {
    margin: 10px 82px;
    height: 500px;

    .cooperativePartner_container {
        width: 60%;
        margin: 0 300px 0 370px;
    }

    .main_catchword_h2 {
        font-size: 28px;
        font-weight: 400;
        //letter-spacing: 10px;
        color: #666;
        text-align: center;
        margin-bottom: 40px;
    }

    .cooperativePartner-list {
        overflow: hidden;
        line-height: 18px;
        list-style-type: none;
        float: left;
    }

    .cooperativePartner-item {
        width: 150px;
        float: left;
        height: 120px;
    }

    img {
        // border-left: 1px dotted #ccc;
        // margin: 20px;
        width: 128px;
        height: 110px;
    }

    &:first-child {
        img {
            border-left: 0;
        }
    }
}

.productRecommendation {
    height: 380px;
}

.homeBottomPart {
    height: 420PX;
}

/* 小屏幕（平板，小于等于768px） */
// @media only screen and (max-width: 768px) and (min-width: 414px) {
@media (max-width: 768px) {
    .cooperativePartner_container {
        margin: 100px auto;
        // z-index: 2000px;
    }

    // 新闻中心标题
    .cooperativePartner_title {
        height: 77rem;

        h3 {
            font-size: 100px;
            padding-top: 26.5rem;
        }

        p {
            width: 84%;
            padding: 3.25rem 8%;
            font-size: 3.5rem;
        }

        .title_connect {
            // width: 110rem;
            position: relative;
            display: flex;

            .title_line {
                width: 10rem;
                height: 0.0625rem;
                margin: 10% 0 0 58%;
                display: none;
            }

            small {
                // width: 30rem;
                font-size: 4.125rem;
                border-radius: 1.25rem;
                top: 56%;
                // display: none;

                span {
                    font-size: 4.125rem;
                    font-weight: 300;
                }
            }
        }
    }

    .main_catchword_h2 {
        font-size: 4.75rem !important;
        font-weight: 600;
        //letter-spacing: 0.125rem;
    }

    //优势
    .cooperativePartner_superiority {
        height: 90.25rem;
        padding: 2rem;

        .main_catchword_h2 {
            font-size: 5.75rem;
            font-weight: bold;
            color: #4b4646;
            //letter-spacing: 0.125rem;
        }

        ul {

            li {
                width: 44%;
                height: 25rem;
                padding: 0.3125rem 2rem;

                img {
                    width: 7.25rem;
                    height: 7.25rem;
                    display: block;
                    margin: 0 0 1.25rem 43%;
                }

                strong {
                    font-size: 4.25rem;
                }

                p {
                    font-size: 2.5rem;
                }
            }
        }
    }

    //合作伙伴
    .cooperativePartner {
        height: 140rem;
        margin: 0.625rem 0.125rem;

        .cooperativePartner_container {
            width: 86%;
            margin: 3rem 16rem;
            text-align: center;
        }

        .main_catchword_h2 {
            font-size: 5.75rem;
            font-weight: bold;
            color: #4b4646;
            //letter-spacing: 0.125rem;
        }

        .cooperativePartner-list {
            width: 32%;

        }

        .cooperativePartner-item {
            width: 22.375rem;
            float: left;
            height: 17.5rem;
        }

        img {
            width: 18.375rem !important;
            height: 17.5rem !important;
        }
    }

    .productRecommendation {
        height: 179.75rem;
    }

    .floatingWindow {
        // height: 60rem;
    }

    .container_architecture {
        height: 54rem;
        width: 100%;
        margin: 13rem auto;
        .main_catchword_h2{
            margin-top: 14rem !important;
        }
        img{
            height:51rem;
            width:calc(100% - 10rem);
            border-radius: 10px;
            object-fit: contain;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }
    }

    .common_superiority {
    // height: 1900px;
        width: 95%;
        margin: 50px auto;
        position: relative;

        ul {
            width: 100%;
            margin: 0 auto;
            margin-top: 30px;
            //   border-radius: 10px;
            //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        }

        ul li {
            width: 90%;
            margin-right: 1%;
            z-index: 99;
            padding: 30px 5%;
            background: #fff;
            display: flex;
            border-radius: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            margin-top: 10px;

            .viewLeft,
            .viewRight {
                padding: 0px 9%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                img {
                    width: 520px;
                    height: 300px;
                    border-radius: 5px;
                    display: block;
                    justify-content: space-between;
                }

                strong {
                    color: #333;
                    font-weight: 400;
                    margin-top: 1.6rem;
                    font-size: 3.3rem;
                    //letter-spacing: 2px;
                }

                p {
                    font-size: 2.3rem;
                    text-indent: 2rem;
                    line-height: 3.6rem;
                    //letter-spacing: 2px;
                    color: #5e6d82;
                }
            }
        }
    }
}

//  最小型号（小于414px）
@media (max-width: 414px) {

}
</style>
